import pkceChallenge from 'pkce-challenge';

//code_verifierを暗号化してbase64エンコードする関数
const GeneratePKCE = () => {
    const pkce = pkceChallenge()
    return pkce
}  

//Stateの値をランダムに生成
const GenerateState = ():string => {
    const state = Math.random().toString(32).substring(2)
    return state
}
//認証コードを発行する
const Authorize = () => {
    const pkce = GeneratePKCE()
    const state = GenerateState()
    // const url = "https://goshuin-customer-dev.auth.ap-northeast-1.amazoncognito.com/oauth2/authorize/?indentity_provider=COGNITO&response_type=code&client_id=470p0qaqb9ur0n3trtkv9tksq5&redirect_uri=http://localhost:3000&state="+state+"&code_challenge_method=S256&code_challenge="+pkce.code_challenge //開発
    const url = "https://goshuin-customer-prod.auth.ap-northeast-1.amazoncognito.com/oauth2/authorize/?indentity_provider=COGNITO&response_type=code&client_id=62nn7eaihdskehg9mi9lto5mc7&redirect_uri=https://239de60e86dac4b6ea22c59aaf9b3894.nomasse.jp&state="+state+"&code_challenge_method=S256&code_challenge="+pkce.code_challenge //本番
    return {
        "url":url,
        "code_verifier":pkce.code_verifier,
        "state":state
    }
}
export default Authorize