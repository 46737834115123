// Topページ
/**
 * 御酒印帳APIの認可コードを発行する
 * 認可コードが無ければ御酒印帳のログイン画面を表示
 * 認可コードが存在していれば以下のカードを表示する
 * 1. 一般向けQRカード発行
 * 2. 法人向けQRカード発行
 * 3. 発行照会
 * 上のボタンを押してその画面に遷移させる
*/
import { useState,useRef } from 'react';
import { 
    Card, CardActionArea, CardContent, CardMedia,
} from '@mui/material';
import { Link,useLocation } from "react-router-dom";
import axios from "axios"
import { useCookies } from 'react-cookie';
import Authorize from '../utils/Authorize';

import '../styles/Common.css'
import '../styles/Card.css'

import generalImage from "../images/general.jpeg"
import corporationImage from "../images/corporation.jpeg"
import inquiryImage from "../images/inquiry.jpeg"

type AuthorizeType = {
    url:string,
    code_verifier:string,
    state:string
}

type TokenType = {
    id_token:string,
    access_token:string,
    refresh_token:string,
}
//メイン関数
const Top = () => {
    const [location,] = useState(useLocation())
    const authorize = useRef<AuthorizeType>(Authorize())
    const [cookies, setCookie, removeCookie] = useCookies(["code_verifier"]);
    const tokens:TokenType = {id_token:"",access_token:"",refresh_token:""}
    // Topにアクセスしたときにcodeが無ければAuthorizeの処理
    // Authorizeのcode_verifierをcookieに保存
    // authorizeのurlに遷移
    // ログイン
    // Topに帰ってくる
    // codeがついている
    // cookieの値を取得
    // それらをtoken取得APIのパラメータにいれる
    // post
    // cookieの削除
    // urlパラメータからcodeを取得
    const PostTokens = (tokens:TokenType) => {
        const url = "https://l42cqy34p3.execute-api.ap-northeast-1.amazonaws.com/dev"
        axios.post(url,JSON.parse(JSON.stringify(tokens)))
        .then(response =>{
            console.log(response.data)
        }).catch(err => {
            console.log(err)
        })
    }

    if(location.search === ""){
        setCookie("code_verifier",authorize.current.code_verifier)
        console.log("set cookie",authorize.current.code_verifier)
        window.location.href = authorize.current.url
    }else{
        var code_string = location.search.slice(1) //先頭の?を削除
        var code_array = code_string.split("&")
        var code = code_array[0].replace("code=","")

        // const url = "https://goshuin-customer-dev.auth.ap-northeast-1.amazoncognito.com/oauth2/token" //開発
        const url = "https://goshuin-customer-prod.auth.ap-northeast-1.amazoncognito.com/oauth2/token" //本番
        const params = {
            grant_type:"authorization_code",
            // client_id:"470p0qaqb9ur0n3trtkv9tksq5", //開発
            client_id:"62nn7eaihdskehg9mi9lto5mc7", //本番
            scope:"openid",
            redirect_uri:"http://localhost:3000",
            code:code,
            code_verifier:cookies.code_verifier
        }
        const config = {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            }
        }
        console.log(params)
        axios.post(url,params,config)
        .then((response) => {
            tokens.id_token = response.data.id_token
            tokens.access_token = response.data.access_token
            tokens.refresh_token = response.data.refresh_token
            console.log(tokens)
            PostTokens(tokens)
            /* 他にPOST成功した処理があれば記入する */
        }).catch((error) => {
            console.log(error)
            console.log("request NG!")
        })
        removeCookie("code_verifier")

    }

    return(
        <div>
            <div>
                <h1 className='content-header'>かんますQRコード一括発行</h1>
                <Card className='top-card'>
                    <CardActionArea >
                        <Link to={"/general"}><CardContent style={{fontSize:"25px"}}>一般向けQRカード発行</CardContent>
                            <CardMedia
                                component="img"
                                height="90"
                                image={generalImage}
                                alt="General"
                                sx={{objectFit:"contain"}}
                                >
                            </CardMedia>
                        </Link>
                    </CardActionArea>
                    
                </Card>
                <Card className='top-card'>
                    <CardActionArea >
                        <Link to={"/corporation"}><CardContent style={{fontSize:"25px"}}>法人向けQRカード発行</CardContent>
                            <CardMedia
                                component="img"
                                height="90"
                                image={corporationImage}
                                alt="Corporation"
                                sx={{objectFit:"contain"}}
                                >
                            </CardMedia>
                        </Link>
                    </CardActionArea>
                </Card>
                <Card className='top-card'>
                    <CardActionArea >
                        <Link to={"/inquiry"}><CardContent style={{fontSize:"25px"}}>発行照会</CardContent>
                            <CardMedia
                                component="img"
                                height="90"
                                image={inquiryImage}
                                alt="Inquiry"
                                sx={{objectFit:"contain"}}
                                >
                            </CardMedia>
                        </Link>
                    </CardActionArea>
                </Card>
            </div>
        </div>
    )
}

export default Top
