// 法人向けQRカード発行ページ
/**
 * 
*/
import {useState,useEffect} from "react"
import { Button,  TextField, FormControl, MenuItem, Alert, CircularProgress, RadioGroup,
    FormControlLabel,Radio
} from '@mui/material';
import axios from 'axios';

import "../styles/Common.css"

// unixtimeをYYYY-MM-DDに変換する関数(unixtime:number)
const ConvertUnixtimeToString = (unixtime:number):string => {
    if(unixtime === 0) return "無期限"
    else {
        const date = new Date(unixtime)
        const year:String = date.getFullYear().toString()
        const month:String = ('0' + (date.getMonth() + 1)).slice(-2)
        const day:String = ('0' + date.getDate()).slice(-2)
        return `${year}-${month}-${day}`
    }
}

// 有効期限（発行日の次の月から2ヶ月後の月末を計算する関数)
const GenerateExpires = ():number => {
    const date = new Date()
    date.setDate(1)
    date.setMonth(date.getMonth() + 3) //次の月 + 2ヶ月後 + 月末(0)を取得するためにその次の月
    date.setDate(0)
    console.log(date.getTime())
    return date.getTime()
}

// 法人一覧の要素
type CorporationType = {
    id: number,
    name : string,
}

//メイン関数
const CorporationCreateQR = () => {
    const [number_issue,setNumberIssue] = useState(1)
    const [number_drink,] = useState(6)
    const [expires,] = useState<number>(GenerateExpires())
    const [target_issue,setTargetIssue] = useState("")
    const [corporation_list,setCorporationList] = useState<Array<CorporationType>>([])

    const [res,setRes]= useState(Object) //発行ボタンを押したあとのレスポンスの状態
    const [alert,setAlert] = useState(false) //発行に成功したか失敗したかのアラートの表示切替用
    const [vali_number_issue,setValiNumberIssue] = useState(true) //入力フォームの正常異常、どれかが異常ならfalse
    const [progress_circular,setProgressCircular] = useState(false) //発行ボタンを押してロード中のぐるぐる
    const [const_or_additional,setConstOrAdditional] = useState("const")     //月額分の枚数を発行するか追加分を発行するか
    const [is_subscription,setIsSubscription] = useState(true) //月額分にチャック(true) 追加(false)

    //チケット発行処理に投げるパラメータ
    var qr_array = {
        "sheets":number_issue, //月額分のときはない
        "count":number_drink,
        "expired":expires,
        "name":target_issue,
        "isSubscription":is_subscription,
    }

    // レンダリングの初回だけ実行して法人一覧を取得
    useEffect(() => {
        const url = "https://js362i7ebb.execute-api.ap-northeast-1.amazonaws.com/dev/"  
        axios.get(url)
        .then((response) => {
            const data = JSON.parse(response.data.body)
            setCorporationList(data)
        }).catch(err => {
            console.log(err)
        })
    },[])

    // 発行ボタンを押したとき
    const IssueHandler = (event:any) => {
        setProgressCircular(true)
        const url = "https://7kxxu5gvr4.execute-api.ap-northeast-1.amazonaws.com/dev"
        const params = JSON.parse(JSON.stringify(event))
        console.log(params)
        axios.post(url, params)
        .then((response) => {
            console.log(response.data)
            setRes(response.status)
            setAlert(true)
            setProgressCircular(false)
        }).catch((error) => {
            setRes(error.message)
            setAlert(true)
            setProgressCircular(false)
        })
    }

     //アラートの×ボタンを押したときにアラートを削除する関数
    const DisableAlert = () =>{
        setAlert(false)
    }

    // 入力ボックスに値が入ったときに変更をかける
    const ChangeNumberIssue = (event:any) =>{
         //バリデーションチェック
        // マイナスになったらエラー
        // 100を超えたらエラー
        if(event.target.value <= 0){
            console.log("0以下はダメ")
            setValiNumberIssue(false)
        }else if(event.target.value >= 5){
            console.log("5以上はダメ")
            setValiNumberIssue(false)
        }else if(event.target.value === undefined || event.target.value === null){
            setValiNumberIssue(false)
        }else{
            setValiNumberIssue(true)
        }
        setNumberIssue(Number(event.target.value))
    }

    //発行対象が変更されたとき
    const ChangeTargetIssue = (event:any) => {
        setTargetIssue(event.target.value)
    }

    //ラジオボタンに変更が入ったとき
    const ChangeConstOrAdditional = (event:any) => {
        setConstOrAdditional(event.target.value)
        if(event.target.value === "const"){
            setIsSubscription(true)
        }else{
            setIsSubscription(false)
        }
    }

    return(
        <div>
            <div>
                <h2 className="content-header">法人向けQRカード発行</h2>
            </div>
            <div>
            {(() =>{ 
                if(alert){
                    if(res === 200){
                        return <Alert id="alert" onClose={DisableAlert} severity="success">発行に成功しました</Alert>
                    }else{
                        return <Alert id="alert" onClose={DisableAlert} severity="error">発行に失敗しました</Alert>
                    }
                }
            })()}
            </div>
            <div>
            {(() => {
                if(progress_circular) {
                    return  <CircularProgress />
                }
            })()}
            </div>
            <div>
                <FormControl>
                    <div className="input-content-border">
                        <p className="input-content-header">発行対象</p>
                        <TextField className="input-content-value" id="target_issue" label="選択してください" variant="outlined"  required={true}   defaultValue={target_issue} onChange={ChangeTargetIssue} select>
                            {corporation_list.map((corporation) => {
                                return  <MenuItem value={corporation.name}>{corporation.name}</MenuItem>
                            })}
                        </TextField>
                    </div>
                    <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                    >
                        <FormControlLabel value="const" control={<Radio checked={const_or_additional === "const"} onChange={ChangeConstOrAdditional}/>} label="月額分の枚数" />
                        <FormControlLabel value="additional" control={<Radio checked={const_or_additional === "additional"} onChange={ChangeConstOrAdditional}/>} label="追加発行" />
                    </RadioGroup>
                    {(() => {
                        if(const_or_additional === "additional"){
                            return(
                                <div className="input-content-border">
                                    <p className="input-content-header">追加発行枚数</p>
                                    <TextField type="number" className="input-content-value" id="number_issue" label="数値のみ" variant="outlined" required={true}   defaultValue={number_issue} onChange={ChangeNumberIssue} />
                                </div>
                            )
                        }
                    })()}
                    <div className="input-content-border">
                        <p className="input-content-header">杯数確認</p>
                        <TextField  className="input-content-value" id="number_drink" label="変更不可" variant="outlined" required={true} value={number_drink} aria-readonly/>
                    </div>
                    <div className="input-content-border">
                        <p className="input-content-header">有効期限確認</p>
                        <TextField className="input-content-value" id="expires"  label="変更不可" variant="outlined" required={true}  value={ConvertUnixtimeToString(expires)} aria-readonly/>
                    </div>
                    
                </FormControl>  
            </div>
            <div style={{paddingTop:"50px"}}>
                {(() => {
                        if(vali_number_issue === true &&  !(const_or_additional === "additional" && target_issue === "全社")){
                            return <Button className="btn-positive" onClick={(()=>{IssueHandler(qr_array)})}>上記の条件で発行</Button>
                        }else{
                            return <Button className="btn-positive" onClick={(()=>{IssueHandler(qr_array)})} disabled>上記の条件で発行</Button>
                        }
                })()}
                <Button className="btn-negative" href="/"  sx={{marginLeft:"30px"}}>戻る</Button>
            </div>
        </div>
    )
}
export default CorporationCreateQR