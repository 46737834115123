import React from 'react';
import './App.css';
import {Routes, Route} from 'react-router';
import Top from './pages/Top'
import GeneralCreateQR from './pages/GeneralCreateQR';
import CorporationCreateQR from './pages/CorporationCreateQR';
import Inquiry from './pages/Inquiry';

const App = () => {
  return (
    <div className="App">
        <Routes>
          <Route path="/" element={<Top />}/>
          <Route path="/general" element={<GeneralCreateQR/>} />
          <Route path="/corporation" element={<CorporationCreateQR />} />
          <Route path='/inquiry' element={<Inquiry/>}></Route>
        </Routes>
    </div>
  );
}

export default App;
