import React from 'react';
import {useCSVDownloader} from 'react-papaparse'
import "../styles/Common.css"

//現在日時取得　ファイル名にする
const padZero =  (num:number):string => {
    return (num < 10 ? "0" : "") + num
}

const getCurrentDatetime = ():string => {
    const now = new Date()
    return "" + now.getFullYear() + padZero(now.getMonth() + 1) + padZero(now.getDate())+ padZero(now.getHours()) + padZero(now.getMinutes()) + padZero(now.getSeconds())
}

//csvダウンロード関数
const CSVDownloader = (props:any) => {
    const { CSVDownloader,Type } = useCSVDownloader()
    const filename = props.published_pdf ? "PDF発行済みリスト_"+ getCurrentDatetime() : "PDF未発行リスト_"+ getCurrentDatetime()
    // var csv_json = ""
    //ヘッダーとデータを受け取ってjsonを作成する
    // props.data.forEach((element:any) => {
    //     csv_json += "シリアル番号:"+element.serial_number+",発行日:"+element.create_at+",杯数:"+element.number_drink+",有効期限:"+element.expires+",会社名:"+element.corporation_name+","
    // })
    
    // csv_json = JSON.stringify(csv_json)
    // csv_json = JSON.parse(csv_json)
    // console.log(csv_json)
    return (
        <CSVDownloader className="csv-export-button" type={Type.Button} filename={filename} bom={true} config={{delimiter:","}} data={props.data}>
            {props.buttonname}
        </CSVDownloader>
    )
}

export default CSVDownloader