// 発行照会ページ
/**
 * 初期状態は検索条件だけ出す。
 * 検索結果で表にする
 * PDFにしたシリアル番号とかの表を出す
 * 再発行処理をできるようにする
 * CSVで出力できるようにする
 * 条件で絞れる 
 * ・個人 or 法人
 * ・発行日の日付指定、範囲指定
 * ・杯数
 * ・有効期限ごと
 * 
*/
import React,{useState,useMemo,useEffect} from 'react';
import {useTable,  Column} from 'react-table'
import axios from 'axios';
import { 
    // Button,   MenuItem,
    Alert, Button,CircularProgress, FormControl, Radio, FormControlLabel, RadioGroup,
} from '@mui/material';
import CSVDownloader from '../components/CSVDownloader';
//syles
import '../styles/Common.css'
import '../styles/Table.css'

//テーブルの型定義
type Props = {
    columns:Column[]
    data : any
}

var checked_array:string[] = []

//テーブルを生成する関数
const Table:React.FC<Props> = ({ columns, data }) => {
    // Use the state and functions returned from useTable to build your UI
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        } = useTable({
            columns,
            data,
        })

    useEffect(() => {
        checked_array.length = 0
        const row = document.querySelectorAll<HTMLInputElement>('input[type=checkbox]')
        for (let i = 0;i < row.length;i++) {
            row[i].checked = true
            checked_array.push(i.toString())
        }
    },[data])

    //チェックボックスが押されたとき
    const ChangeCheckBox = (event:any) => {
        //checkされたとき列番号を配列に格納、外れたときに削除
        if(event.target.checked === true){
            checked_array.push(event.target.value);
        }else{
            checked_array = checked_array.filter((item:any,index:any) =>{
                return item !== event.target.value
            })
        }
    }
    console.log(checked_array)
    // Render the UI for your table
    return (
        <div className='table-parent'>
            <table {...getTableProps()}>
                <thead>
                {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        <th className="fixed-header"></th>
                        {headerGroup.headers.map(column => (
                        <th className="fixed-header" {...column.getHeaderProps()}>{column.render('Header')}</th>
                        ))}
                    </tr>
                ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                {rows.map((row, i) => {
                    prepareRow(row)
                    return (
                    <tr {...row.getRowProps()}>
                        <td><input value={i} type="checkbox" name="pdf-check" onClick={ChangeCheckBox} defaultChecked/></td>
                        {row.cells.map(cell => {
                            return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                        })}
                    </tr>
                    )
                })}
                </tbody>
            </table>
        </div>
    )
}

// unixtimeをYYYY-MM-DDに変換する関数(unixtime:number)
const ConvertUnixtimeToString = (unixtime:number):string => {
    if(unixtime === 0) return "無期限"
    else {
        const date = new Date(unixtime)
        const year:String = date.getFullYear().toString()
        const month:String = ('0' + (date.getMonth() + 1)).slice(-2)
        const day:String = ('0' + date.getDate()).slice(-2)
        return `${year}-${month}-${day}`
    }
}

//メイン関数
const Inquiry = () => {
    const [res,setRes]= useState(Object) //発行ボタンを押したあとのレスポンスの状態
    const [alert,setAlert] = useState(false) //発行に成功したか失敗したかのアラートの表示切替用
    const [progress_circular,setProgressCircular] = useState(false) //発行ボタンを押してロード中のぐるぐる
    const [print_table,setPrintTable] = useState(false) //テーブルを表示するかの状態

    //個人か法人か
    const [prefix,setPrefix] = useState("A")
    //PDFを発行済みか
    const [published_pdf,setPublishedPDF] = useState(false)
    
    //個人・法人のラジオボタンに変更が入ったとき
    const ChangeGeneralOrCorporation = (event:any) => {
        if(event.target.value === "corporation"){
            setPrefix("B")
        }else{
            setPrefix("A")
        }
    }

    //PDFのラジオボタンに変更が入ったとき
    const ChangePubliedPDF = (event:any) => {
        if(event.target.value === "unPublishedPDF"){
            setPublishedPDF(false)
        }else if(event.target.value === "publishedPDF"){
            setPublishedPDF(true)
        }
    }

    //テーブルに表示する値を格納する
    var table_data:any = []
    const [table_element,setTableElement] = useState<{serial_number:string,create_at:string,number_drink:number,expired:string,corporation_name:string}[]>([])

    //検索用リクエスト
    const [search_array,setSearchArray] = useState({
        "prefix":prefix, //true or false 個人はfalse
        "isPublishedPDF":published_pdf, //PDFを発行済みかどうか
    })
    
    useEffect(() => {
        setSearchArray({
            "prefix":prefix, //A or B 個人はA
            "isPublishedPDF":published_pdf, //PDFを発行済みかどうか
        })
        // checked_array.length = 0
    },[prefix,published_pdf])

    //検索ボタンを押したとき
    const SearchIssue = (event:any) => {
        const url = " https://8dw29zba1g.execute-api.ap-northeast-1.amazonaws.com/dev/"
        const params = JSON.parse(JSON.stringify(event))
        console.log(params)
        setProgressCircular(true)

        axios.post(url,params)
        .then(response => {
            response.data.body.forEach((element:any) => {
                table_data.push({
                    serial_number:element.serialNumber.S,
                    create_at: ConvertUnixtimeToString(Number(element.created_at.N)),
                    number_drink:element.count.N,
                    expires:ConvertUnixtimeToString(Number(element.expired.N)),
                    corporation_name:element.name.S
                })
            })
            setTableElement(table_data.sort())
            setPrintTable(true)
            setProgressCircular(false)
        }).catch(err => {
            console.log(err)
            setProgressCircular(false)
        })
    }

    //アラートの×ボタンを押したときにアラートを削除する関数
    const DisableAlert = () =>{
        setAlert(false)
    }

    //一括発行ボタンを押したとき
    // シリアルナンバーの配列を渡す
    const PDFIssue = () => {
        setProgressCircular(true)
        const url = "https://im56kfpv79.execute-api.ap-northeast-1.amazonaws.com/dev/"
        const serial_array:string[] = []
        // checked_arrayを昇順でソート
        checked_array.sort((a,b) =>{
            return Number(a) - Number(b)
        })
        checked_array.forEach((element) => {
            serial_array.push(table_element[Number(element)].serial_number)
        })
        const params = {
            "data":serial_array
        }
        
        console.log(checked_array)
        console.log(params)
        axios.post(url,params)
        .then((response) => {
            //エラー処理
            console.log(response.data)
            window.open(response.data)
            setRes(response.status)
            setAlert(true)
        }).catch((error) => {
            setRes(error.message)
            setAlert(false)
        })
        setProgressCircular(false)
    }


    const data = useMemo(
        () => 
            table_element
        ,
        [table_element]
    )

    //テーブルの列定義
    const columns = useMemo(
        () => [
            {
                Header:"シリアル番号",
                accessor:"serial_number",
                // disableSortBy:false
            },
            {
                Header:"発行日",
                accessor:"create_at",
                // disableSortBy:true,
            },
            
            {
                Header:"杯数",
                accessor:"number_drink",
            },
            {
                Header:"有効期限",
                accessor:"expires",
                // disableSortBy:true,
            },
            {
                Header: "会社名",
                accessor:"corporation_name",
            }
        ],
        []
    )

    return(
        <div>
            <h2 className='content-header'>発行照会</h2>
            <div>
            {(() =>{ 
                if(alert){
                    if(res === 200){
                        return <Alert id="alert" onClose={DisableAlert} severity="success">発行に成功しました</Alert>
                    }else{
                        return <Alert id="alert" onClose={DisableAlert} severity="error">発行に失敗しました</Alert>
                    }
                }
            })()}
            </div>
            <div>
            {(() => {
                if(progress_circular) {
                    return  <CircularProgress />
                }
            })()}
            </div>

            <FormControl style={{display:"inline-block"}}>
                <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    style={{display: "inline-block"}}
                >
                    <FormControlLabel value="general" control={<Radio checked={prefix === "A"} onChange={ChangeGeneralOrCorporation}/>} label="個人" />
                    <FormControlLabel value="corporation" control={<Radio checked={prefix === "B"} onChange={ChangeGeneralOrCorporation}/>} label="法人" />
                </RadioGroup>
                <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    style={{display: "inline-block"}}
                >
                    <FormControlLabel value="unPublishedPDF" control={<Radio checked={published_pdf === false} onChange={ChangePubliedPDF}/>} label="PDF未発行" />
                    <FormControlLabel value="publishedPDF" control={<Radio checked={published_pdf === true} onChange={ChangePubliedPDF}/>} label="PDF発行済み" />
                </RadioGroup>
                <Button style={{marginLeft:"30px"}} className="btn-positive" onClick={(()=>{SearchIssue(search_array)})}>検索</Button>
            </FormControl>
            
            {/* 検索ボタンが押されたあとテーブルの表示をする */}
            {(() => {
                if(print_table) {
                    return  <Table columns={columns} data={data}></Table>
                }
            })()}
            

            <div style={{paddingTop:"50px"}}>
                {(() => {
                    if(print_table && data.length > 0) {
                        return  <><Button className="btn-positive" onClick={(()=>{PDFIssue()})}>一括PDF発行</Button>
                        <CSVDownloader data={data} buttonname="CSV出力" published_pdf={published_pdf}/></>
                    }else if(print_table && data.length === 0) {
                        // 検索されたデータがなかった時
                        return <div style={{marginTop:"-500px",paddingBottom:"100px"}}>データがありません。</div>
                    }
                })()}
                
                <Button className="btn-negative" href="/" sx={{marginLeft:"30px"}}>戻る</Button>
            </div>
        </div>
    )
}

export default Inquiry