// 一般向けQRカード発行ページ
/**
 * 発行枚数を入力
 * 杯数を指定　チケットの条件を選んだものを表示のみ
 * カードの有効期限設定　表示のみ
 * 発行ボタン、戻るボタン→チケット選択画面
 * 発行ボタンを押したらQR一括発行アプリAPI→御酒印帳APIを実行してQRを発行
*/
// import React libraries
import React,{useState} from "react"
import { Button,  TextField, FormControl,  Alert,CircularProgress, 
} from '@mui/material';
import axios from 'axios';

// import css files
import '../styles/Common.css'

// unixtimeをYYYY-MM-DDに変換する関数(unixtime:number)
const ConvertUnixtimeToString = (unixtime:number|string):string => {
    if(unixtime === "") return "";
    if(unixtime === 0) return "無期限"
    else {
        const date = new Date(unixtime)
        const year:String = date.getFullYear().toString()
        const month:String = ('0' + (date.getMonth() + 1)).slice(-2)
        const day:String = ('0' + date.getDate()).slice(-2)
        return `${year}-${month}-${day}`
    }
}

// 有効期限（発行日から１年半後を計算する関数)
const GenerateExpires = ():number => {
    const date = new Date()
    date.setFullYear(date.getFullYear() + 1)
    date.setMonth(date.getMonth() + 6)
    return date.getTime()
}

//メイン関数
const GeneralCreateQR:React.FC = () => {
    const [number_issue,setNumberIssue] = useState(1)
    const [number_drink,] = useState(6)
    const [expires,] = useState<number>(GenerateExpires())
    const [res,setRes]= useState(Object) //発行ボタンを押したあとのレスポンスの状態
    const [alert,setAlert] = useState(false) //発行に成功したか失敗したかのアラートの表示切替用
    const [vali_number_issue,setValiNumberIssue] = useState(true) //入力フォームの正常異常、どれかが異常ならfalse
    const [progress_circular,setProgressCircular] = useState(false) //発行ボタンを押してロード中のぐるぐる
    //チケット発行処理に投げるパラメータ
    var qr_array = {
        "sheets":number_issue,
        "count":number_drink,
        "expired":expires
    }

    //アラートの×ボタンを押したときにアラートを削除する関数
    const DisableAlert = () =>{
        setAlert(false)
    }

    //発行ボタンを押したとき
    const IssueHandler = (event:any) => {    
        setProgressCircular(true)
        const url = "https://a2rqag4y77.execute-api.ap-northeast-1.amazonaws.com/dev"
        const params = JSON.parse(JSON.stringify(event))
        axios.post(url, params)
        .then((response) => {
            console.log(response.data)
            setRes(response.status)
            setAlert(true)
            setProgressCircular(false)
        }).catch((error) => {
            setRes(error.message)
            setAlert(true)
            setProgressCircular(false)
        })
    }

    // 入力ボックスに値が入ったときに変更をかける
    const ChangeNumberIssue = (event:any) =>{
        //バリデーションチェック
        // マイナスになったらエラー
        // 100を超えたらエラー
        if(event.target.value <= 0){
            console.log("0以下はダメ")
            setValiNumberIssue(false)
        }else if(event.target.value >= 50){
            console.log("5以上はダメ")
            setValiNumberIssue(false)
        }else if(event.target.value === undefined || event.target.value === null){
            setValiNumberIssue(false)
        }else{
            setValiNumberIssue(true)
        }
        setNumberIssue(Number(event.target.value))
    }

    return(
        <div>
            <div>
                <h2 className="content-header">一般向けQRカード発行</h2>
            </div>
            <div>
            {(() =>{ 
                if(alert){
                    if(res === 200){
                        return <Alert id="alert" onClose={DisableAlert} severity="success">発行に成功しました</Alert>
                    }else{
                        return <Alert id="alert" onClose={DisableAlert} severity="error">発行に失敗しました</Alert>
                    }
                }
            })()}
            </div>
            <div>
            {(() => {
                if(progress_circular) {
                    return  <CircularProgress />
                }
            })()}
            </div>
            <div>
                <FormControl>
                    <div className="input-content-border">
                        <p className="input-content-header" >発行枚数を入力してください</p>
                        <TextField type="number" className="input-content-value" id="number_issue" label="数値のみ" variant="outlined"  required={true} InputLabelProps={{shrink: true}} defaultValue={number_issue} onChange={ChangeNumberIssue} />
                    </div>
                    <div className="input-content-border">
                        <p className="input-content-header" >杯数確認</p>
                        <TextField className="input-content-value" id="number_issue" label="変更不可" variant="outlined" required={true} value={number_drink} aria-readonly/>
                    </div>
                    <div className="input-content-border">
                        <p className="input-content-header" >有効期限設定</p>
                        <TextField className="input-content-value" id="expires" label="変更不可" variant="outlined" required={true} helperText  value={ConvertUnixtimeToString(expires)} aria-readonly>
                        </TextField>
                    </div>
                    
                </FormControl>  
            </div>               
            <div style={{paddingTop:"50px"}}>
                {(() => {
                    if(vali_number_issue === true) {
                        return <Button className="btn-positive" onClick={(()=>{IssueHandler(qr_array)})}>上記の条件で発行</Button>
                    }else{
                        return <Button className="btn-positive" onClick={(()=>{IssueHandler(qr_array)})} disabled>上記の条件で発行</Button>
                    }
                })()}
                <Button className="btn-negative" href="/" sx={{marginLeft:"30px"}}>戻る</Button>
            </div>
        </div>
    )
}

export default GeneralCreateQR